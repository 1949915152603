import React from 'react';

function Footer() {
  return (
    <footer className="container-fluid footer_section">
  <p>
  NASEEBA.MURSHID
    @ 2024 Developed by
    <a href="https://instagram.com/sayikumarr/" style={{color:"orange"}}> Sayikumarr </a>
     | 
     <a href="https://pavitech.in/" style={{color:"cyan"}}> PAViTech </a>
  </p>
</footer>

  );
}

export default Footer;
